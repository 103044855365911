<!-- 
Page for user to reset their password.
-->


<template>
    <v-container v-if="$store.state.user">

        <!-- Page Title -->
        <v-row class="text-center mt-10">
            <v-col class="mb-4">
                <h1 class="display-2 font-weight-bold mb-3">Password Reset<v-icon large>mdi-lock</v-icon></h1>
            </v-col> 
        </v-row>

        <!-- Admin panel to select any individual's password to change -->
        <div v-if="this.$store.state.user.admin">
            <v-row align="center">
                <v-col class="col-4"></v-col>
                <v-col>
                    <v-select :items="items" v-model="resetChoice" label="Who's account would you like to reset?" solo></v-select>
                </v-col>
                <v-col></v-col>
            </v-row>
        </div>

        <!-- ADMIN Card with desired new password inputs -->
        <!-- <div v-if="this.$store.state.user.admin && (this.resetChoice == this.$store.state.user.admin)"> -->
        <div v-if="this.$store.state.user.admin && (this.resetChoice == 'Not my account')">
            <v-card class="mx-auto my-12 text-center" max-width="674">
                <template slot="progress">
                    <v-progress-linear color="deep-purple" height="55" indeterminate></v-progress-linear>
                </template>

                <v-card-text>
                    <v-row align="center" class="mx-0"></v-row>
                    <div class="my-4 text-subtitle-1"></div>
                    <div>
                        <p>Please enter the username of the account, as well as a new temporary password.  Note that both password entries must match for the update to be successful.
                            After creating the temporary password for the specified account, the account owner can then update their account password.
                        </p>
                    </div>
                </v-card-text>

                <v-card-text>
                    <!-- <v-text-field required v-model="uname" label="Username" solo></v-text-field> -->
                    <v-select :items="allUsernames" v-model="uname" label="Username" solo></v-select>
                    <v-text-field required v-model="newPass1" label="New Password" solo></v-text-field>
                    <v-text-field required v-model="newPass" label="Confirm New Password" solo></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-col>
                        <v-btn color="primary" @click="resetPassword">Submit</v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </div>

        <!-- NON-ADMIN Card with desired new password inputs -->
        <div v-if="!this.$store.state.user.admin || (this.$store.state.user.admin && this.resetChoice == 'My Account')">
            <v-card class="mx-auto my-12 text-center" max-width="674">
                <template slot="progress">
                    <v-progress-linear color="deep-purple" height="55" indeterminate></v-progress-linear>
                </template>

                <v-card-text>
                    <v-row align="center" class="mx-0"></v-row>
                    <div class="my-4 text-subtitle-1"></div>
                    <div>
                        <p>Please enter your desired password.  Note that both entries must match for the update to be successful.</p>
                    </div>
                </v-card-text>

                <v-card-text>
                    <v-text-field required v-model="newPass1" label="New Password" solo></v-text-field>
                    <v-text-field required v-model="newPass" label="Confirm New Password" solo></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-col>
                        <v-btn color="primary" @click="resetPassword">Submit</v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </div>

        <!-- Snackbar @ bottom of screen for "passwords do not match" message -->
        <template>
            <div class="text-center ma-2">
                <v-snackbar
                v-model="snackbar"
                >
                {{ text }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Close
                    </v-btn>
                </template>
                </v-snackbar>
            </div>
        </template>

    </v-container>
</template>


<script>
import AuthenticationService from '../services/AuthenticationService'

export default {
    name: 'resetPassword',
    data () {
        return {
            snackbar: false,
            text: ``,
            items: ['My Account', 'Not my account'],
            resetChoice: "",
            uname: "",
            newPass1: "",
            newPass: "",
            response: null,
            allUsers: [],
            allUsernames: []
        }
    },

    async mounted() {
        // Get all login usernames from DB and add to usernames array (used later to verify valid username entry)
        this.allUsers = await AuthenticationService.getAllUsers()
        this.allUsers.data.rows.forEach(element => {
            this.allUsernames.push(element.login);
        })       
    },

    methods: {

        async resetPassword() {

            if (this.resetChoice == "Not my account") {
                if (!this.allUsernames.includes(this.uname)) {
                    this.text = 'The entered username does not exist.'
                    this.snackbar = true;
                    this.newPass1 = "";
                    this.newPass = "";
                    this.uname = "";                 
                }
                // If both inputs match & are both filled
                else if ((this.newPass1 == this.newPass) && this.newPass) {
                    try {
                        const response = await AuthenticationService.updatePassword({
                            login: this.uname,
                            crypted_password: this.newPass,
                            //name: this.$store.state.user.name,
                            //email: this.$store.state.user.email,
                            //admin: this.$store.state.user.admin
                        })

                        this.response = response;
                        this.$router.push({ path: '/' })
                        this.$store.dispatch('setToken', response.data.token)
                        this.$store.dispatch('setUser', response.data.user)

                    } catch (error) {
                        this.error = error.response.data.error

                    }
                }
                else {
                    // Password entries do not match -- display snackbar message and reset entrry fields
                    this.text = 'Password entries do not match.'
                    this.snackbar = true;
                    this.newPass1 = "";
                    this.newPass = "";                }
            }
            else {
                // If both inputs match
                if ((this.newPass1 == this.newPass) && this.newPass) {
                    try {
                        const response = await AuthenticationService.updatePassword({
                            login: this.$store.state.user.login,
                            name: this.$store.state.user.name,
                            email: this.$store.state.user.email,
                            crypted_password: this.newPass,
                            admin: this.$store.state.user.admin
                        })

                        this.response = response;
                        this.$router.push({ path: '/' })
                        this.$store.dispatch('setToken', response.data.token)
                        this.$store.dispatch('setUser', response.data.user)

                    } catch (error) {
                        this.error = error.response.data.error

                    }
                }
                else {
                    // Password entries do not match -- display snackbar message and reset entrry fields
                    this.text = 'Password entries do not match.'
                    this.snackbar = true;
                    this.newPass1 = "";
                    this.newPass = "";
                }
            }
        }
    }

}
</script>