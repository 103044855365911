import Api from '@/services/Api'

export default {
  register (payload) { return Api().post('registerNewUser', payload) },
  login (payload) { return Api().post('LogIn', payload) },
  getAllUsers (payload) { return Api().get('getAllUsers', payload) },
  updatePassword (payload) { return Api().post('updatePassword', payload) },
  deleteUser (payload) { return Api().post('deleteUser', payload) }
}

// Example call...
// AuthenticationService.register({
//     email: 'testing@gmail.com',
//     password: '123456'
// })
